import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import { Card, CardContent, CardMedia, Button } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useUserStateDispatch } from '../../context/UserContext';
import { HL7ToDateToSrtDate } from '../../helpers/dateFormat';
import config from '../../config';

import LinkIcon from '@mui/icons-material/Link';

const useStyles = makeStyles((theme) => ({
  paper: {
    borderRadius: theme.spacing(1),
    height: '100%',
  },
  marginMid: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },

  marginBottom: { marginBottom: theme.spacing(2) },
  title: { color: '#000', width: '50%' },
  dateRage: {
    marginLeft: 'auto',
    color: '#fff',
    backgroundColor: theme.palette.warning.light,
    padding: `${theme.spacing(0.4)} ${theme.spacing(1)}`,
    borderRadius: theme.spacing(0.5),
    height: theme.spacing(3.6),
    fontSize: 14,
  },
  startText: {
    marginTop: theme.spacing(1),
    width: '100%',
  },
  Accordion: {
    border: 0,
    backgroundColor: 'transparent',
    boxShadow: 'none',

    '&::before': {
      display: 'none',
    },
    '& .MuiAccordionSummary-root': {
      minHeight: 22,
      margin: 0,
      padding: 0,
    },
    '& .MuiAccordionSummary-content': {
      margin: 0,
      color: theme.palette.primary.light,
      fontWeight: 400,
      fontSize: 14,
    },
    '& .MuiAccordionDetails-root ': {
      margin: 0,
      padding: 0,
      marginTop: theme.spacing(2),
      display: 'block',
      background: 'rgba(255, 255, 255, 0.8 )',
      borderRadius: 4,
    },
    '& .MuiIconButton-root': {
      padding: 0,
      color: theme.palette.primary.light,
    },
    '& .MuiCardActions-root': {
      padding: `${theme.spacing(0)} ${theme.spacing(0.1)}`,
    },
  },
}));

import { useParams } from 'react-router-dom';
import { getPromoActions } from '../../actions/user';
import Loading from '../../components/Loading';
import isEmpty from '../../helpers';

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
const PromoItem = ({ item, promoId }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    userState: {
      user: { lang },
    },
  } = useUserStateDispatch();

  const [expanded, setExpanded] = useState(
    promoId ? `panel${promoId}` : false,
  );
  const handleChange = (id) => (event, isExpanded) => {
    setExpanded(isExpanded ? `panel${id}` : false);
  };
  //console.log('=========== item', item);

  const bgImg = `${config.baseURLApi}${item.imageUrl}&appCode=${config.APP_CODE}`;
  const ts = item.text.split('.');
  const start = ts.shift();
  const end = ts.join('.');

  return (
    <Card className={classes.paper}>
      <CardMedia
        component="img"
        height="222"
        image={bgImg}
        alt="green iguana"
      />
      <CardContent>
        {item.text.trim() !== '' && (
          <>
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
              }}
            >
              <Typography
                variant="h6"
                component="div"
                className={classes.title}
              >
                {item.description}
              </Typography>
              {(item.startDate != null || item.endDate != null) && (
                <Typography
                  component="div"
                  className={classes.dateRage}
                >
                  {item.startDate != null &&
                    HL7ToDateToSrtDate(item.startDate, lang)}{' '}
                  &mdash;{' '}
                  {item.endDate != null &&
                    HL7ToDateToSrtDate(item.endDate, lang)}
                </Typography>
              )}
              <Typography
                component="div"
                variant="body2"
                className={classes.startText}
              >
                {start}
              </Typography>
            </Box>
            {end.trim() !== '' ? (
              <Accordion
                defaultExpanded={false}
                classes={{
                  root: classes.Accordion,
                }}
                expanded={expanded === `panel${item.id}`}
                onChange={handleChange(item.id)}
              >
                <AccordionSummary
                  classes={{
                    root: classes.Accordion,
                  }}
                  expandIcon={<ExpandMoreIcon />}
                >
                  {t('COMPONENT.DATAILS')}
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body2">{end}</Typography>
                  {item.url && (
                    <Link href={item.url} target={'_blank'}>
                      <Button
                        size="small"
                        color="primary"
                        startIcon={<LinkIcon />}
                      >
                        {t('COMPONENT.LINK_TO_ACTION')}
                      </Button>
                    </Link>
                  )}
                </AccordionDetails>
              </Accordion>
            ) : (
              item.url && (
                <Link href={item.url} target={'_blank'}>
                  <Button
                    size="small"
                    color="primary"
                    startIcon={<LinkIcon />}
                  >
                    {t('COMPONENT.LINK_TO_ACTION')}
                  </Button>
                </Link>
              )
            )}
          </>
        )}
      </CardContent>
    </Card>
  );
};
/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
export default function PromoIndex() {
  // console.log("timeSlots", timeSlots);
  const { promoId } = useParams();
  const [promo, setPromo] = useState({ isLoaded: true, date: [] });

  useEffect(() => {
    getPromoActions(setPromo);
  }, []);

  const { t } = useTranslation();

  console.log(promo);

  return (
    <Grid container spacing={3}>
      <Grid size={12}>
        <Typography variant="h3">Личные сообщения</Typography>
      </Grid>
      {!promo.isLoaded && (
        <Grid size={12} alignContent={'center'}>
          <Loading />
        </Grid>
      )}
      {!isEmpty(promo.data) &&
        promo.data.map((item) => (
          <Grid size={12} key={item.id}>
            <PromoItem item={item} promoId={promoId} />
          </Grid>
        ))}
    </Grid>
  );
}
