import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import {
  CircularProgress,
  TextField as Input,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
//components
import Button from '@mui/material/Button';
//i18n
import { useTranslation } from 'react-i18next';
import isEmpty from '../../helpers';

import { useUserStateDispatch } from '../../context/UserContext';

const useStyles = makeStyles((theme) => ({
  creatingButtonContainer: {
    marginBottom: theme.spacing(3),

    height: 46,
    display: 'flex',
  },
  submitButton: {
    height: 46,
    textTransform: 'none',

    width: '54%',
    minWidth: 240,
    marginTop: 12,
  },
}));

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling

function SignUpFormConfirm({
  values,
  handleChange,
  errors,
  handleSubmit,
  setActiveTabId,
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    userState: { serverResponse, isLoaded },
  } = useUserStateDispatch();
  const [counter, setCounter] = React.useState(
    serverResponse?.lifeTime ? serverResponse.lifeTime : 900,
  );

  // Counter
  useEffect(() => {
    if (counter === 0) {
      setActiveTabId(1);
    }
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  return (
    <>
      <Input
        name="confirmationCode"
        variant="outlined"
        value={values.confirmationCode}
        onChange={handleChange}
        margin="normal"
        label={t('SIGN.UP_CONFIRM_CODE')}
        type="tel"
        fullWidth
        required
        error={errors.confirmationCode != null}
        helperText={
          errors.confirmationCode != null && errors.confirmationCode
        }
      />
      <Grid container mt={0.5} mb={3} alignItems="center" spacing={3}>
        <Grid size={{ xs: 12, md: 6 }}>
          {!isLoaded ? (
            <CircularProgress
              size={26}
              className={classes.loginLoader}
            />
          ) : (
            <Button
              disabled={
                isEmpty(values.confirmationCode) ||
                errors.confirmationCode != null
              }
              onClick={handleSubmit}
              variant="contained"
              color="primary"
              fullWidth
              className={classes.submitButton}
              sx={{ mx: 'auto' }}
            >
              {t('SIGN.UP_SUBMIT_CONFIRM')}
            </Button>
          )}
        </Grid>
        <Grid size={{ xs: 12, md: 6 }} textAlign="right">
          {counter > 0 && (
            <Typography variant="body1" mt={1.5}>
              {t('SIGN.IN_BY_QR_LIFETIME', { sec: counter })}
            </Typography>
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default SignUpFormConfirm;
