import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Collapse as Fade,
  TextField as Input,
  Alert,
} from '@mui/material';

// styles

import Typography from '@mui/material/Typography';

//i18n
import { useTranslation } from 'react-i18next';
import isEmpty, { isString } from '../../helpers';

interface ShowResponse {
  serverResponse:
    | null
    | string
    | {
        action?: string;
        error?: string;
        text?: string;

        deliveryMethod?: string;
        phoneConfirmationCodeInfo?: {
          deliveryMethod?: string;
        };
      };
  okKey?: string;
}

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
const ShowResponse: React.FC<ShowResponse> = ({
  serverResponse,
  okKey = 'SIGN.UP_OK',
}) => {
  const { t } = useTranslation();
  //console.log('serverResponse', serverResponse);
  if (isString(serverResponse) || serverResponse == null)
    return (
      <Fade
        in={!!serverResponse}
        style={
          serverResponse == null
            ? { display: 'none' }
            : { display: 'flex', width: '100%' }
        }
      >
        <Alert severity="error">
          <Typography variant="h6">
            {serverResponse === 'OK'
              ? t(okKey)
              : serverResponse === 'WRONG_LOGIN_OR_PASSWORD'
              ? t('SIGN.UP_WRONG_LOG_OR_PASS')
              : serverResponse === 'EMAIL_ALREADY_REGISTERED'
              ? t('SIGN.UP_EMAIL_ALREADY_REG')
              : serverResponse === 'MAY_LOGIN'
              ? t('SIGN.UP_MAY_LOGIN')
              : serverResponse === 'WRONG_CONFIRMATION_CODE'
              ? t('SIGN.UP_CONFIRM_CODE_WRONG')
              : serverResponse || t('SIGN.IN_ERRORS')}
          </Typography>
        </Alert>
      </Fade>
    );
  if (
    serverResponse?.action ||
    serverResponse?.error ||
    serverResponse?.text
  ) {
    const inFade =
      serverResponse?.action ||
      serverResponse?.error ||
      serverResponse?.text
        ? true
        : false;

    return (
      <Fade
        in={inFade}
        style={inFade ? { display: 'flex' } : { display: 'none' }}
      >
        <Alert
          severity={
            serverResponse?.action === 'OK' ? 'success' : 'warning'
          }
          sx={{ mb: 4 }}
        >
          <Typography variant="h6">
            {serverResponse?.action === 'OK' && okKey
              ? t(okKey)
              : serverResponse?.action === 'ACCOUNT_NOT_FOUND'
              ? t('SIGN.CH_PASS_ACCOUNT_NOT_FOUND')
              : serverResponse?.action === 'CONFIRM_CODE_SENT' &&
                serverResponse?.deliveryMethod === 'Email'
              ? t('SIGN.CH_CONFIRM_CODE_SENT_EMAIL')
              : serverResponse?.action === 'CONFIRM_CODE_SENT' &&
                serverResponse?.deliveryMethod === 'Sms'
              ? t('SIGN.CH_CONFIRM_CODE_SENT_SMS')
              : serverResponse?.action === 'CONFIRM_CODE_SENT' &&
                serverResponse?.deliveryMethod === 'VoiceCall'
              ? t('SIGN.CH_CONFIRM_CODE_SENT_VOICE')
              : serverResponse?.action === 'WRONG_LOGIN_OR_PASSWORD'
              ? t('SIGN.UP_WRONG_LOG_OR_PASS')
              : serverResponse?.action === 'EMAIL_ALREADY_REGISTERED'
              ? t('SIGN.UP_EMAIL_ALREADY_REG')
              : serverResponse?.action === 'MAY_LOGIN'
              ? t('SIGN.UP_MAY_LOGIN')
              : serverResponse?.action === 'WRONG_CONFIRMATION_CODE'
              ? t('SIGN.UP_CONFIRM_CODE_WRONG')
              : serverResponse?.text
              ? serverResponse?.text
              : serverResponse?.error
              ? serverResponse?.error
              : serverResponse?.action}
          </Typography>
        </Alert>
      </Fade>
    );
  }
};
export default ShowResponse;
